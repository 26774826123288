/**
 * Container for the Laser objects of the game
 */

import ArrayRepository from "./arrayRepository";

class LaserRepository extends ArrayRepository {
    constructor() {
        super();
    }

    /**
     * Draw all the lasers
     */
    activateLasers(field) {
        this.elements.forEach(function (laser) {
            let allShapes = field.getAllShapes();
            let lines = laser.raytrace(allShapes, null);
            field.p5.stroke('red');
            field.p5.strokeWeight(15);

            //Draw all the lines that are found by ray tracing
            let next = 0;
            for (let current = 0; current < lines.points.length; current++) {
                next = current + 1;

                if (next >= lines.points.length) {
                    let x1 = lines.points[current].x;
                    let y1 = lines.points[current].y;
                    let x2 = x1 + lines.lastVector.x * laser.vectorMultiplier;
                    let y2 = y1 + lines.lastVector.y * laser.vectorMultiplier;
                    field.p5.line(x1,y1,x2,y2);
                } else {
                    let x1 = lines.points[current].x;
                    let y1 = lines.points[current].y;
                    let x2 = lines.points[next].x;
                    let y2 = lines.points[next].y;
                    field.p5.line(x1, y1, x2, y2);
                }
            }

            //render laser origin
            let size = 50;
            field.p5.image(field.source, laser.x - (size / 2), laser.y - (size / 2), size, size);

        });
    }

    /**
     * Unbind all elements in the array from the mouse
     */
    unbindMouse() {
        this.elements.forEach((laser) => {
            if (laser.mouseControl || laser.laserControl) {
                laser.mouseControl = false;
                laser.laserControl = false;
            }
        });
    }
}

export default LaserRepository;
