import Shape from "./entities/shape";
import Style from "./entities/style";

class Level {

    /**
     * Tutorials is where you start with
     * @param p5 the P5 library object
     */
    constructor(p5) {
        this.p5 = p5;
        this.tutorials = [];
        this.mainLevels = [];
        this.index = 0;
        this.tutorial = true;

        this.block = this.createBlock();
        this.speed = 30;
    }

    createBlock () {
        let points = [];
        points.push(this.p5.createVector(0, 0));
        points.push(this.p5.createVector(1920, 0));
        points.push(this.p5.createVector(1920, 1080));
        points.push(this.p5.createVector(0, 1080));

        let shape = new Shape(0, 0, points, new Style(5, "black", "black"), this.p5);
        shape.shape = "rectangle";
        shape.size = Math.min(1920, 1080);
        return shape
    }

    /**
     * Creates level arrays from JSON
     * @param JSONLevels the JSON array
     * @param p5
     * @returns {Level} a levels container element
     */
    static createLevelFromJSON(JSONLevels, p5) {
        let levelOrder = new Level(p5);
        JSONLevels.tutorials.forEach((level) => levelOrder.tutorials.push(level));
        JSONLevels.mainLevels.forEach((level) => levelOrder.mainLevels.push(level));
        levelOrder.shuffleOrder();
        return levelOrder;
    }

    /**
     * resets the field to the tutorials level
     */
    reset() {
        if (!(this.tutorial && this.index == 1)) {
            this.tutorial = true;
            this.index = 0;
            setTimeout(function() {
                this.p5.nextLevel()
            }, 500);
            this.speed = -30;
            console.log("Resetting the game due to inactivity");
        }
    }

    render() {
        this.block.render();

        this.block.points[2].y -= this.speed;
        this.block.points[3].y -= this.speed;

        if (this.block.points[2].y < 0) {
            this.block.points[2].y = 0;
        }
        if (this.block.points[2].y > 1080) {
            this.block.points[2].y = 1080;
            this.speed = 30;
        }
        if (this.block.points[3].y < 0) {
            this.block.points[3].y = 0;
        }
        if (this.block.points[3].y > 1080) {
            this.block.points[3].y = 1080;
            this.speed = 30;
        }
    }

    /**
     * Shuffle the order of main levels
     */
    shuffleOrder() {

        let currentIndex = this.mainLevels.length, temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = this.mainLevels[currentIndex];
            this.mainLevels[currentIndex] = this.mainLevels[randomIndex];
            this.mainLevels[randomIndex] = temporaryValue;
        }
    }

    /**
     * Load a next level, and when all is completed, reshuffle the list and go again.
     */
    nextLevel() {
        if (this.tutorial) {
            if (this.index >= this.tutorials.length) {
                this.index = 0;
                this.tutorial = false;
                this.p5.loadLevel(this.mainLevels[this.index]);
            } else {
                this.shuffleOrder();
                this.p5.loadLevel(this.tutorials[this.index]);
            }
        } else {
            if (this.index >= this.mainLevels.length) {
                this.shuffleOrder();
                this.index = 0;
            }
            this.p5.loadLevel(this.mainLevels[this.index]);
        }

        this.index++;
    }
}
export default Level;