import Style from "./style";

class Shape {

    /**
     * Constructor of Shape
     * @param x x-coordinate of the Shape
     * @param y y-coordinate of the Shape
     * @param points list with all the corners of the Shape
     * @param styleObject the style state object for this shape
     * @param p5 the P5 library object
     * @param [size] the size of a predefined shape
     * @param [width] the widht of a predefined shape
     */
    constructor(x, y, points, styleObject, p5, size) {
        this.mouseControl = false;
        this.pointControl = null;

        this.x = x;
        this.y = y;
        if (typeof points.shape === "undefined") {
            this.points = points;
            this.shape = "";
            this.type = null;
        } else {
            this.points = Shape.createPoints(x,y,size,points.shape, points.type, p5);
            this.shape = points.shape;
            this.type = points.type;
        }
        this.styleObject = styleObject;
        this.p5 = p5;

        // Initialize type and set the refection boolean to true
        this.size = size;
        this.calculateSize();

        this.toReflect = true;
        this.grid = false;
        this.rotation = 0;
    }

    /**
     * Method to add a point to a shape.
     * @param x x-coordinate of the point
     * @param y y-coordinate of the point
     */
    addPoint(x, y) {
        this.points.push(this.p5.createVector(x, y));
    }

    /**
     * Method to change if a object reflects or not.
     */
    changeReflect() {
        this.toReflect = !this.toReflect;
    }

    /**
     * Method to check if a point is corner of a shape.
     * @param x x-coordinate of the point which could be corner of the shape
     * @param y y-coordinate of the point which could be corner of the shape
     * @returns {boolean} returns true if it is a corner, else false
     */
    checkPoints(x, y) {
        for (let i = 0; i < this.points.length; i++) {
            if (this.points[i].x === x && this.points[i].y === y) {
                return true;
            }
        }
        return false;
    }

    /**
     * create the points for a given shape.
     * @param x center x coordinate
     * @param y center y coordinate
     * @param size the size of the shape
     * @param shape the shape to make
     * @param type the type to make
     * @param p5 the p5 library
     * @returns {Array} Returns a new shape object with the needed points
     */
    static createPoints (x, y, size, shape, type, p5) {
        switch (shape) {
            case "rectangle":
                return Shape.createSquare(x, y, size, p5);
            case "diamond":
                return Shape.createDiamond(x, y, size, p5);
            case "triangle":
                return Shape.createTriangle(x, y, size, type, p5);
            case "plus":
                return Shape.createPlus(x, y, size, p5);
            case "hexagon":
                return Shape.createHexagon(x, y, size, type, p5);
            case "arrow":
                return Shape.createArrow(x, y, size, type,p5);
        }
    };

    static createRectangleShape(x, y, width, height, styleObject, p5) {
        let leftX = x - width * 0.5;
        let rightX = leftX + width;
        let topY = y - height * 0.5;
        let bottomY = topY + height;

        let points = [];
        points.push(p5.createVector(leftX, topY));
        points.push(p5.createVector(rightX, topY));
        points.push(p5.createVector(rightX, bottomY));
        points.push(p5.createVector(leftX, bottomY));

        let shape = new Shape(x, y, points, styleObject, p5);
        shape.shape = "rectangle";
        shape.size = Math.min(width, height);
        return shape
    }


    /**
     * Create a square shape points.
     * @param x center x coordinate
     * @param y center y coordinate
     * @param size the size of the shape
     * @param p5 the p5 library
     * @returns {Array} Returns a new shape object with the needed points
     */
    static createSquare(x, y, size, p5) {
        let leftX = x - size * 0.5;
        let rightX = leftX + size;
        let topY = y - size * 0.5;
        let bottomY = topY + size;

        let points = [];
        points.push(p5.createVector(leftX, topY));
        points.push(p5.createVector(rightX, topY));
        points.push(p5.createVector(rightX, bottomY));
        points.push(p5.createVector(leftX, bottomY));

        return points
    }

    /**
     * Create a diamond shape points.
     * @param x center x coordinate
     * @param y center y coordinate
     * @param size the size of the shape
     * @param p5 the p5 library
     * @returns {Array} Returns a new shape object with the needed points
     */
    static createDiamond(x, y, size, p5) {
        let leftX = x - size * 0.5;
        let rightX = leftX + size;
        let topY = y - size * 0.5;
        let bottomY = topY + size;

        let points = [];
        points.push(p5.createVector(x, topY));
        points.push(p5.createVector(rightX, y));
        points.push(p5.createVector(x, bottomY));
        points.push(p5.createVector(leftX, y));

        return points
    }

    /**
     * Creates the vector points of a triangle given an direction
     * @param x Center x coordinate
     * @param y Center y coordinate
     * @param size The size of the shape
     * @param direction The direction the triangle faces (Up, down, left, right)
     * @param p5 The p5 library
     * @returns {Array} Containing the points
     */
    static createTriangle(x, y, size, direction, p5) {
        switch (direction.toLowerCase()) {
            case "up":
                return Shape.createTrianglePointsUp(x, y, size, p5);
            case "right":
                return Shape.createTrianglePointsRight(x, y, size, p5);
            case "left":
                return Shape.createTrianglePointsLeft(x, y, size, p5);
            case "down":
            default:
                return Shape.createTrianglePointsDown(x, y, size, p5);
        }
    }

    /**
     * create triangle points facing up.
     * @param x center x coordinate
     * @param y center y coordinate
     * @param size the size of the shape
     * @param p5 the p5 library
     * @returns {Array} Returns a new shape object with the needed points
     */
    static createTrianglePointsUp(x, y, size, p5) {
        let points = [];
        points.push(p5.createVector(x, y - 0.5 * size));
        points.push(p5.createVector(x - 0.5 * size, y + 0.5 * size));
        points.push(p5.createVector(x + 0.5 * size, y + 0.5 * size));

        return points;
    }

    /**
     * create triangle points facing right.
     * @param x center x coordinate
     * @param y center y coordinate
     * @param size the size of the shape
     * @param p5 the p5 library
     * @returns {Array} Returns a new shape object with the needed points
     */
    static createTrianglePointsRight(x, y, size, p5) {
        let points = [];
        points.push(p5.createVector(x + 0.5 * size, y));
        points.push(p5.createVector(x - 0.5 * size, y + 0.5 * size));
        points.push(p5.createVector(x - 0.5 * size, y - 0.5 * size));

        return points;
    }

    /**
     * create triangle points facing down.
     * @param x center x coordinate
     * @param y center y coordinate
     * @param size the size of the shape
     * @param p5 the p5 library
     * @returns {Array} Returns a new shape object with the needed points
     */
    static createTrianglePointsDown(x, y, size, p5) {
        let points = [];
        points.push(p5.createVector(x, y + 0.5 * size));
        points.push(p5.createVector(x + 0.5 * size, y - 0.5 * size));
        points.push(p5.createVector(x - 0.5 * size, y - 0.5 * size));

        return points;
    }

    /**
     * create a triangle shape left.
     * @param x center x coordinate
     * @param y center y coordinate
     * @param size the size of the shape
     * @param p5 the p5 library
     * @returns {Array} Returns a new shape object with the needed points
     */
    static createTrianglePointsLeft(x, y, size, p5) {
        let points = [];
        points.push(p5.createVector(x - 0.5 * size, y));
        points.push(p5.createVector(x + 0.5 * size, y - 0.5 * size));
        points.push(p5.createVector(x + 0.5 * size, y + 0.5 * size));

        return points
    }

    /**
     * create a plus shape
     * @param x center x coordinate
     * @param y center y coordinate
     * @param size the size of the shape
     * @param p5 the p5 libary
     * @returns {Array} Returns a new shape object with the needed points
     */
    static createPlus(x, y, size, p5) {
        let leftX = x - size * 0.5;
        let rightX = leftX + size;
        let topY = y - size * 0.5;
        let bottomY = topY + size;

        let points = [];

        //top
        points.push(p5.createVector(x - 0.1 * size, topY));
        points.push(p5.createVector(x + 0.1 * size, topY));
        //to right
        points.push(p5.createVector(x + 0.1 * size, y - 0.1 * size));
        //right
        points.push(p5.createVector(rightX, y - 0.1 * size));
        points.push(p5.createVector(rightX, y + 0.1 * size));
        //to bottom
        points.push(p5.createVector(x + 0.1 * size, y + 0.1 * size));
        //bottom
        points.push(p5.createVector(x + 0.1 * size, bottomY));
        points.push(p5.createVector(x - 0.1 * size, bottomY));
        //to left
        points.push(p5.createVector(x - 0.1 * size, y + 0.1 * size));
        //left
        points.push(p5.createVector(leftX, y + 0.1 * size));
        points.push(p5.createVector(leftX, y - 0.1 * size));
        //to top
        points.push(p5.createVector(x - 0.1 * size, y - 0.1 * size));

        return points;
    }

    /**
     * create a hexagon point shape points.
     * @param x center x coordinate
     * @param y center y coordinate
     * @param size the size of the shape
     * @param {String} type Type of the Hexagon (Point or Flat)
     * @param p5 the p5 libary
     * @returns {Array} Returns a new shape object with the needed points
     */
    static createHexagon(x, y, size, type, p5) {
        switch (type.toLowerCase()) {
            case "point":
                return Shape.createHexagonPointPoints(x, y, size, p5);
            case "flat":
            default:
                return Shape.createHexagonFlatPoints(x, y, size, p5);
        }
    }

    /**
     * create a flat hexagon shape.
     * @param x center x coordinate
     * @param y center y coordinate
     * @param size the size of the shape
     * @param p5 the p5 libary
     * @returns {Array} Returns a new shape object with the needed points
     */
    static createHexagonPointPoints(x, y, size, p5) {
        let points = [];
        points.push(p5.createVector(x, y - 0.5 * size));
        points.push(p5.createVector(x + 0.5 * size, y - 0.25 * size));
        points.push(p5.createVector(x + 0.5 * size, y + 0.25 * size));
        points.push(p5.createVector(x, y + 0.5 * size));
        points.push(p5.createVector(x - 0.5 * size, y + 0.25 * size));
        points.push(p5.createVector(x - 0.5 * size, y - 0.25 * size));

        return points;
    }

    /**
     * create a flat hexagon shape.
     * @param x center x coordinate
     * @param y center y coordinate
     * @param size the size of the shape
     * @param p5 the p5 libary
     * @returns {Array} Returns a new shape object with the needed points
     */
    static createHexagonFlatPoints(x, y, size, p5) {
        let points = [];
        points.push(p5.createVector(x - 0.25 * size, y - 0.5 * size));
        points.push(p5.createVector(x + 0.25 * size, y - 0.5 * size));
        points.push(p5.createVector(x + 0.5 * size, y));
        points.push(p5.createVector(x + 0.25 * size, y + 0.5 * size));
        points.push(p5.createVector(x - 0.25 * size, y + 0.5 * size));
        points.push(p5.createVector(x - 0.5 * size, y));

        return points
    }

    /**
     * create a arrow shape points.
     * @param x center x coordinate
     * @param y center y coordinate
     * @param size the size of the shape
     * @param {String} direction The direction the arrow faces (Up, down, left, right)
     * @param p5 the p5 libary
     * @returns {Array} Returns a new shape object with the needed points
     **/
    static createArrow(x, y, size, direction, p5) {
        switch (direction.toLowerCase()) {
            case "up":
                return Shape.createArrowPointsUp(x, y, size, p5);
            case "right":
                return Shape.createArrowPointsRight(x, y, size, p5);
            case "left":
                return Shape.createArrowPointsLeft(x, y, size, p5);
            case "down":
            default:
                return Shape.createArrowPointsDown(x, y, size, p5);
        }
    }

    /**
     * create a arrow up shape.
     * @param x center x coordinate
     * @param y center y coordinate
     * @param size the size of the shape
     * @param p5 the p5 libary
     * @returns {Array} Returns a new shape object with the needed points
     */
    static createArrowPointsUp(x, y, size, p5) {
        let points = [];
        points.push(p5.createVector(x, y - 0.5 * size));
        points.push(p5.createVector(x + 0.5 * size, y));
        points.push(p5.createVector(x + 0.25 * size, y));
        points.push(p5.createVector(x + 0.25 * size, y + 0.5 * size));
        points.push(p5.createVector(x - 0.25 * size, y + 0.5 * size));
        points.push(p5.createVector(x - 0.25 * size, y));
        points.push(p5.createVector(x - 0.5 * size, y));

        return points;
    }

    /**
     * create a triangle shape.
     * @param x center x coordinate
     * @param y center y coordinate
     * @param size the size of the shape
     * @param p5 the p5 libary
     * @returns {Array} Returns a new shape object with the needed points
     */
    static createArrowPointsRight(x, y, size, p5) {
        let points = [];
        points.push(p5.createVector(x + 0.5 * size, y));
        points.push(p5.createVector(x, y + 0.5 * size));
        points.push(p5.createVector(x, y + 0.25 * size));
        points.push(p5.createVector(x - 0.5 * size, y + 0.25 * size));
        points.push(p5.createVector(x - 0.5 * size, y - 0.25 * size));
        points.push(p5.createVector(x, y - 0.25 * size));
        points.push(p5.createVector(x, y - 0.5 * size));

        return points;
    }

    /**
     * create a triangle shape.
     * @param x center x coordinate
     * @param y center y coordinate
     * @param size the size of the shape
     * @param p5 the p5 libary
     * @returns {Array} Returns a new shape object with the needed points
     */
    static createArrowPointsDown(x, y, size, p5) {
        let points = [];
        points.push(p5.createVector(x, y + 0.5 * size));
        points.push(p5.createVector(x - 0.5 * size, y));
        points.push(p5.createVector(x - 0.25 * size, y));
        points.push(p5.createVector(x - 0.25 * size, y - 0.5 * size));
        points.push(p5.createVector(x + 0.25 * size, y - 0.5 * size));
        points.push(p5.createVector(x + 0.25 * size, y));
        points.push(p5.createVector(x + 0.5 * size, y));

        return points;
    }

    /**
     * create a triangle shape.
     * @param x center x coordinate
     * @param y center y coordinate
     * @param size the size of the shape
     * @param p5 the p5 libary
     * @returns {Array} Returns a new shape object with the needed points
     */
    static createArrowPointsLeft(x, y, size, p5) {
        let points = [];
        points.push(p5.createVector(x - 0.5 * size, y));
        points.push(p5.createVector(x, y - 0.5 * size));
        points.push(p5.createVector(x, y - 0.25 * size));
        points.push(p5.createVector(x + 0.5 * size, y - 0.25 * size));
        points.push(p5.createVector(x + 0.5 * size, y + 0.25 * size));
        points.push(p5.createVector(x, y + 0.25 * size));
        points.push(p5.createVector(x, y + 0.5 * size));

        return points;
    }

    /**
     * method to return the corners of the shape
     * @returns {Array} the list with all the corners of the shape
     */
    getVectors() {
        return this.points;
    }

    /**
     * method to recalculate the center of the obstacle
     */
    center() {
        let x = 0;
        let y = 0;
        for (let point of this.points) {
            x += point.x;
            y += point.y;
        }
        this.x = x / this.points.length;
        this.y = y / this.points.length;
    }

    /**
     * Set the size of the object
     */
    calculateSize() {
        let minX = Number.MAX_SAFE_INTEGER;
        let maxX = -1;
        let minY = Number.MAX_SAFE_INTEGER;
        let maxY = -1;
        this.points.forEach((point) => {
            minX = Math.min(point.x, minX);
            minY = Math.min(point.y, minY);

            maxX = Math.max(point.x, maxX);
            maxY = Math.max(point.y, maxY);
        });

        this.size = Math.min(maxX - minX, maxY - minY);
    }

    /**
     * Method to update the position of a shape
     * @param x the new x-coordinate of the shape
     * @param y the new y-coordinate of the shape
     */
    update(x, y) {
        this.points.forEach((point) => {
            point.x += (x - this.x);
            point.y += (y - this.y);
        });
        this.x = x;
        this.y = y;
    }

    /**
     * Method to update the position of a point in a shape
     * @param x the new x-coordinate of the point in a shape
     * @param y the new y-coordinate of the point in a shape
     * @param point the point to be updated
     */
    updatePoint(x, y, point) {
        if (this.grid) {
            this.points[point].x = Math.round(x / 30) * 30;
            this.points[point].y = Math.round(y / 30) * 30;
        } else {
            this.points[point].x = x;
            this.points[point].y = y;
        }
        this.center();
    }

    /**
     * Method to render a shape
     */
    render() {
        //Initialize the line surrounding the shape and the color
        this.styleObject.setState(this.p5);

        // Begin drawing the shape
        this.p5.beginShape();
        let shape = this;
        this.points.forEach((point) => {
            shape.p5.vertex(point.x, point.y);
        });
        this.p5.endShape(this.p5.CLOSE);
    }

    /**
     * Method to render a rotated shape around its own origin
     */
    renderRotate() {
        // Store the current canvas settings
        this.p5.push();

        // Translate the canvas to the shape and rotate it
        this.p5.translate(this.x, this.y);
        this.p5.rotate(this.rotation);

        // Store the old x and y and update to 0,0,
        // because the center of the canvas is at the center of the shape
        let oldX = this.x;
        let oldY = this.y;
        this.update(0, 0);

        // Render the shape and restore the old canvas settings
        this.render();
        this.update(oldX, oldY);
        this.p5.pop();
    }

    /**
     * Change the color of the shape
     * @param color the color to which it needs to be changed
     */
    changeColor(color) {
        this.styleObject = new Style(this.styleObject.lineWeight, "black", color);
    }

    /**
     * Method to create a JSON Object out of a shape
     * @returns {*} object containing all the relevant fields of shape
     */
    getJSON() {
        let jsonObject = {};

        //Get basic data
        jsonObject.shape = this.shape;
        jsonObject.type = this.type;
        jsonObject.x = this.x;
        jsonObject.y = this.y;
        jsonObject.toReflect = this.toReflect;

        //Get style object
        jsonObject.styleObject = this.styleObject.styleToJson();

        //Construct vector points
        jsonObject.points = [];
        this.points.forEach((point) => jsonObject.points.push({x: point.x, y: point.y}));

        return jsonObject;
    }

    /**
     * Move the shape based on mouse coordinates.
     * @param {Number} x cor
     * @param {Number} y cor
     */
    move(x, y) {
        if (this.mouseControl && typeof x !== "undefined" && typeof  y !== "undefined") {
            this.update(x, y);

        } else if (this.pointControl != null && typeof x !== "undefined" && typeof  y !== "undefined") {
            this.updatePoint(x, y, this.pointControl);
        }
    }

    /**
     * Method to indicate if a player is hit by the mouse, so that is can be bound to the mouse
     * @param x x-coordinate of the mouse click
     * @param y y-coordinate of the mouse click
     * @returns {boolean} if it hit a player, true, else false
     */
    mouseHit(x, y) {
        // Check if the mouse hit any point
        for (let point of this.points) {
            if (Math.abs(point.x - x) <= 5 && Math.abs(point.y - y) <= 5) {
                this.pointControl = this.points.indexOf(point);
                return true;
            }
        }

        //Check if the mouse hits the center of the shape
        if (Math.abs(this.x - x) <= 0.25 * this.size && Math.abs(this.y - y) <= this.size * 0.25) {
            this.mouseControl = true;
            return true;
        }
        return false;
    }

    /**
     * Method to create a Shape object from a JSON object containing the shape information
     * @param JSONShape JSON object containing a shape
     * @param p5 p5 library
     * @returns {Shape} a Shape object
     */
    static createShapeFromJSON(JSONShape, p5) {
        //get all the points from json
        let points = [];
        JSONShape.points.forEach((point) => points.push(p5.createVector(point.x, point.y)));

        //create style object
        let styleObject = Style.createStyleFromJson(JSONShape.styleObject);

        // create new shape
        let shape = new Shape(JSONShape.x, JSONShape.y, JSONShape.points, styleObject, p5);

        //set extra parameters
        shape.toReflect = JSONShape.toReflect;
        shape.shape = JSONShape.shape;
        shape.type = JSONShape.type;

        return shape;
    }
}

export default Shape;