/**
 * Container for the Shape objects of the game
 */
import ArrayRepository from "./arrayRepository";
import Style from "../entities/style";

class ShapeRepository extends ArrayRepository {
    constructor() {
        super();
    }

    /**
     * Turn off all grid shapes
     */
    toggleGrid() {
        this.elements.forEach((shape) => {
            shape.grid = !shape.grid;
        });
    }

    /**
     * Unbind all shapes in the array from the mouse
     */
    unbindMouse() {
        this.elements.forEach((shape) => {
            if (shape.pointControl != null || shape.mouseControl) {
                shape.mouseControl = false;
                shape.pointControl = null;
            }
        });
    }

    /**
     * Return array of all shapes
     * @returns {Array}
     */
    getShapes() {
        return this.elements;
    }

    /**
     * Find the first element that shares coordinates with the mouseclick and send it to another method to change its reflect
     * @param x x-coordinate of mouse click
     * @param y y-coordinate of mouse click
     */
    checkReflectAtCoordinates(x, y) {
        for (let element of this.elements) {
            if (element.mouseHit(x, y, true)) {
                this.changeReflect(element);
                break;
            }
        }
    }

    /**
     * The actual method to change the reflect
     * @param shape the shape to find and change
     */
    changeReflect(shape) {
        let index = this.elements.indexOf(shape);

        if (index >= 0) {
            this.elements[index].toReflect = !this.elements[index].toReflect;
            if (this.elements[index].toReflect) {
                this.elements[index].styleObject = new Style(5, "black", "black");
            } else {
                this.elements[index].styleObject = new Style(5, "black", "#a8a8a8");
            }
        }
    }

}

export default ShapeRepository;