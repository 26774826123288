/**
 * Container Superclass for game objects
 */
class ArrayRepository {
    constructor() {
        // Elements
        this.elements = [];
    }

    /**
     * Append an element to the array
     * @param element to be added
     */
    add(element) {
        this.elements.push(element);
    }

    /**
     * Returns the size of the array
     * @returns {number}
     */
    size() {
        return this.elements.length;
    }

    /**
     * Remove an element from the array
     * @param element to be removed
     */
    remove(element) {
        let index = this.elements.indexOf(element);

        if (index >= 0) {
            this.elements.splice(index,1);
        }
    }

    /**
     * Find the first element that shares coordinates with the mouseclick and remove it
     * @param x x-coordinate of mouseclick
     * @param y y-coordinate of mouseclick
     */
    removeAtCoordinates(x, y) {
        for (let element of this.elements) {
            if (element.mouseHit(x, y)) {
                this.remove(element);
                break;
            }
        }
    }

    move(x, y) {
        this.elements.forEach((element) => {
            element.move(x, y);
        });
    }

    /**
     * Bind an element to the mouseclick if it shares coordinates with the mouseclick
     * @param x x-coordinate of mouseclick
     * @param y y-coordinate of mouseclick
     * @returns {boolean} whether or not an element was bound
     */
    bindMouse(x, y) {
        for (let element of this.elements){
            if (element.mouseHit(x, y)) {
                return true;
            }
        }
        return false;
    }

    /**
     * Unbind all elements in the array from the mouse
     */
    unbindMouse() {
        this.elements.forEach((element) => {
            if (element.mouseControl) {
                element.mouseControl = false;
            }
        });
    }

    /**
     * Render each element in the array
     */
    render() {
        this.elements.forEach((element) => {
            element.render();
        });
    }

    /**
     * Convert all elements in this array into json
     * @returns {any[]}
     */
    getJSON() {
        return this.elements.map((element) => element.getJSON());
    }
}

export default ArrayRepository;