import Player from "../entities/player"

/**
 * Container for the Player objects of the game
 */

class PlayerRepository {
    constructor() {
        this.startingPlayers = [];
        this.bound = {};
    }

    /**
     * Amount of elements that are bound
     * @returns {number} integer that represents the amount of bound players
     */
    size() {
        return Object.keys(this.bound).length;
    }

    /**
     * Move a player to a coordinate
     * @param id of the player
     * @param x x-coordinate the player will move to
     * @param y y-coordinate the player will move to
     */
    move(id, x, y) {
        if (typeof this.bound[id] === "undefined") {
            if (this.checkForBound(id, x, y)) {
                this.bound[id].move(x, y);
            }
        } else {
            if (this.checkRange(id, x, y)) {
                this.bound[id].move(x, y);
            } else {
                this.deleteBound(id);
            }
        }
    }

    /**
     * Method to check if a x and y are in range
     * @param id of the player
     * @param x x-coordinate the player will move to
     * @param y y-coordinate the player will move to
     * @returns {boolean}
     */
    checkRange(id, x, y) {
        if (x >= 0 && x <= 1920) {
            if (y >= 0 && y <= 1080) {
                return true
            }
        }
        return false;
    }

    /**
     * Method to check if a player needs to be bound
     * @param id of the player
     * @param x x-coordinate the player will move to
     * @param y y-coordinate the player will move to
     */
    checkForBound(id, x, y) {
        for (let i = 0; i < this.startingPlayers.length; i++) {
            if (!this.startingPlayers[i].isBound && this.checkRange(id, x, y)) {
                if (Math.hypot(x - this.startingPlayers[i].x, y - this.startingPlayers[i].y) <= 100) {
                    // Link the bound to the shape
                    this.bound[id] = new Player(x, y, this.startingPlayers[i].size, [], {shape: this.startingPlayers[i].shape}, this.startingPlayers[i].p5);
                    this.bound[id].startingPlayer = i;
                    this.bound[id].isBound = true;

                    // set the starting Player to bound
                    this.startingPlayers[i].isBound = true;
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Move the player using the keyboard strokes
     */
    keyMove() {
        Object.values(this.startingPlayers).forEach((player) => {
            player.keyMove();
        });
    }

    /**
     * Pass mouse movement to all players
     * @param x pos
     * @param y pos
     */
    moveMouse(x, y) {
        Object.values(this.startingPlayers).forEach((player) => {
            player.moveMouse(x, y);
        });
    }

    /**
     * Bind a player to the mouse if they are hit by the mouse
     * @param x x-coordinate of mouse click
     * @param y y-coordinate of mouse click
     * @returns {boolean}
     */
    bindMouse(x, y) {
        for (let player of Object.values(this.startingPlayers)) {
            if (player.mouseHit(x, y)) {
                player.mouseControl = true;
                return true;
            }
        }
        return false;
    }

    /**
     * Unbind all players from the mouse
     */
    unbindMouse() {
        Object.values(this.startingPlayers).forEach((player) => {
            if (player.mouseControl) {
                player.mouseControl = false;
            }
        });
    }

    /**
     * Method to delete a bound player
     * @param id the id that will be deleted
     */
    deleteBound(id) {
        this.startingPlayers[this.bound[id].startingPlayer].isBound = false;
        delete this.bound[id];
    }

    /**
     * Remove player from the dict
     * @param player player to be removed
     * @param id id over the player
     * @returns {boolean} whether or not the player was removed
     */
    remove(player, id) {
        if (typeof id !== "undefined") {
            if (typeof this.bound[id] !== "undefined") {
                this.deleteBound(id);
            }
        } else if (typeof player !== "undefined") {
            for (const [key, value] of Object.entries(this.bound)) {
                if (value === player) {
                    this.deleteBound(key);
                }
            }
        }
    }

    /**
     * Remove the player if they were clicked on by the mouse
     * @param x x-coordinate of mouseclick
     * @param y y-coordinate of mouseclick
     */
    removeAtCoordinates(x, y) {
        for (let i = 0; i < this.startingPlayers.length; i++) {
            if (this.startingPlayers[i].mouseHit(x, y)) {
                this.startingPlayers.splice(i, 1);
                break;
            }
        }
    }

    /**
     * Return all player shapes
     * @returns {Array} that contains the player shapes
     */
    getShapes() {
        let playerShapes = [];

        for (let player of Object.values(this.bound)) {
            playerShapes.push(player);
        }

        for (let i = 0; i < this.startingPlayers.length; i++) {
            let player = this.startingPlayers[i];
            if (!player.isBound) {
                playerShapes.push(player);
            }
        }

        return playerShapes;
    }

    /**
     * Draw all player shapes
     */
    render() {
        for (let player of Object.values(this.bound)) {
            player.render();
        }

        for (let i = 0; i < this.startingPlayers.length; i++) {
            let player = this.startingPlayers[i];
            if (!player.isBound) {
                player.render();
            }
        }
    }

    /**
     * Return the list of players as a json object
     * @returns {any[]}
     */
    getJSON() {
        return this.startingPlayers.map((player) => player.getJSON());
    }
}

export default PlayerRepository;