import Shape from "./shape";
import Style from "./style";

class Player extends Shape {

    /**
     * creates a player
     * @param {Number} x starting x pos
     * @param {Number} y starting y pos
     * @param {Number} size of the player
     * @param {Array} keys
     * @param {Object} shape, contains a shape, and possibly a type if the shape requires it
     * @param {Object} p5 the P5 library object
     */
    constructor(x, y, size, keys, shape, p5) {
        super(x, y, shape, new Style(3, "black", "blue"), p5, size);

        this.color = "blue";

        this.keys = keys.map(key => key.toUpperCase());
        this.isBound = false;

        // Initializing the color
        this.colorCode = 140;
        this.descending = true;
    }

    /**
     * Method to update the color of the player
     */
    updateColorCode() {
        if (this.descending) {
            this.colorCode -= 2;
            if (this.colorCode <= 40) {
                this.descending = false;
            }
        } else {
            this.colorCode += 2;
            if (this.colorCode >= 140) {
                this.descending = true;
            }
        }
    }

    /**
     * Move the player based on mouse coordinates.
     * @param {Number} x coordinate
     * @param {Number} y coordinate
     */
    moveMouse(x, y) {
        if (this.mouseControl && typeof x !== "undefined" && typeof  y !== "undefined") {
            super.update(x,y);
        }
    }

    /**
     * Move the player based on the parameters given.
     * @param x The x coordinate to move to
     * @param y The y coordinate to move to
     */
    move(x, y) {
        super.update(x,y);
    }

    /**
     * Checks the keyboard buttons to see if the player needs to move.
     */
    keyMove() {
        if (this.keys.length === 4) {
            if (this.p5.keyIsDown(this.keys[0].charCodeAt(0))) {
                this.y -= 10;
            }
            if (this.p5.keyIsDown(this.keys[1].charCodeAt(0))) {
                this.y += 10;
            }
            if (this.p5.keyIsDown(this.keys[2].charCodeAt(0))) {
                this.x -= 10;
            }
            if (this.p5.keyIsDown(this.keys[3].charCodeAt(0))) {
                this.x += 10;
            }
        }
    }

    /**
     * Method to update a shape to its new x and y location
     */
    updateShape() {
        super.update(this.x, this.y);
    }

    /**
     * Renders the shape of the player
     */
    render() {
        if (!this.mouseControl) {
            this.updateColorCode();
            this.styleObject.fillColor(this.p5.lerpColor(this.p5.color("blue"), this.p5.color("lightblue"), this.colorCode / 180));
        } else {
            this.styleObject.fillColor("blue");
        }

        super.render();

        if (!this.mouseControl) {
            this.p5.stroke("white");
            this.p5.strokeWeight(3);
            this.p5.noFill();
            this.p5.ellipse(this.x, this.y, this.colorCode, this.colorCode);
        }
    }

    /**
     * Method to indicate if a player is hit by the mouse, so that is can be bound to the mouse
     * @param x x-coordinate of the mouse click
     * @param y y-coordinate of the mouse click
     * @returns {boolean} if it hit a player, true, else false
     */
    mouseHit(x, y) {
        if (Math.abs(this.x - x) <= this.size * 0.25) {
            if (Math.abs(this.y - y) <= this.size * 0.25) {
                this.mouseControl = true;
                return true;
            }
        }

        return false;
    }

    /**
     * Method to create a JSON Object out of a player
     * @returns {*} object containing all the relevant fields needed to create a player
     */
    getJSON() {
        let jsonObject = super.getJSON();
        jsonObject.keys = this.keys;
        jsonObject.size = this.size;
        return jsonObject;
    }

    /**
     * Method to create a player from a JSON Object
     * @param JSONPlayer JSON Object containing a player
     * @param p5 p5 library
     * @returns {Player} a Player object
     */
    static createPlayerFromJSON(JSONPlayer, p5) {
        return new Player(JSONPlayer.x, JSONPlayer.y, JSONPlayer.size, JSONPlayer.keys, JSONPlayer.shape, p5);
    }
}

export default Player;