import Style from "./style";
import Shape from "./shape";

class Movable extends Shape {

    /**
     * Constructor of the movable object.
     * @param x1 Beginning x-coordinate of the object
     * @param y1 Beginning y-coordinate of the object
     * @param x2 Ending x-coordinate of the object
     * @param y2 Ending y-coordinate of the object
     * @param velocity Determines how fast the object should go
     * @param size The size of the object
     * @param shape The shape that this object takes
     * @param toReflect Whether or not the shape should reflect
     * @param color The color of the shape
     * @param p5 the P5 library object
     */
    constructor(x1, y1, x2, y2, velocity, size, shape, toReflect, color, p5) {
        super(x1, y1, shape, new Style(5, "black", color), p5, size);

        this.x1 = x1;
        this.y1 = y1;
        this.x2 = x2;
        this.y2 = y2;

        this.velocity = velocity;
        this.size = size;
        this.color = color;
        this.toReflect = toReflect;
        this.shape = shape.shape;
        this.type = shape.type;

        this.toOther = true;
        this.secondPointControl = false;
    }

    // noinspection JSCheckFunctionSignatures
    /**
     * Method to indicate if a movable is hit by the mouse, so that is can be bound to the mouse
     * @param x x-coordinate of the mouse click
     * @param y y-coordinate of the mouse click
     * @returns {boolean} if it hit a player, true, else false
     */
    mouseHit(x, y) {
        if (Math.abs(this.x - x) <= this.size * 0.25){
            if (Math.abs(this.y - y) <= this.size * 0.25) {
                this.mouseControl = true;
                return true;
            }
        }

        if (x <= this.x2 + 10 && x >= this.x2 - 10){
            if (y <= this.y2 + 10 && y >= this.y2 - 10) {
                this.secondPointControl = true;
                return true;
            }
        }

        return false;
    }

    /**
     * Move the movable based on mouse coordinates.
     * @param {Number} x coordinate
     * @param {Number} y coordinate
     */
    move(x, y) {
        if (this.mouseControl && typeof x !== "undefined" && typeof  y !== "undefined") {
            let xDiff = this.x1 - this.x2;
            let yDiff = this.y1 - this.y2;

            this.x1 = x;
            this.y1 = y;
            this.updateEndPoint(this.x1 - xDiff, this.y1 - yDiff);
            this.updateShape(x, y);
            this.toOther = true;
        }

        if (this.secondPointControl && typeof x !== "undefined" && typeof  y !== "undefined") {
            this.updateShape(this.x1, this.y1);
            this.updateEndPoint(x, y);
            this.toOther = true;
        }
    }

    /**
     * Method to update a shape to its new x and y location for editor purposes.
     */
    updateShape(x, y) {
        super.update(x, y);
    }

    /**
     * Method to change the second x and y coordinates of the movable object.
     * @param x New x2
     * @param y New y2
     */
    updateEndPoint(x, y) {
        this.x2 = x;
        this.y2 = y;
    }

    /**
     * Renders the shape of the object.
     */
    render() {
        super.render();
    }

    /**
     * Moves the object.
     */
    moveObject() {
        if (this.toOther) {
            if ((Math.round(this.x) === Math.round(this.x2)) && (Math.round(this.y) === Math.round(this.y2))) {
                this.toOther = false;
            } else {
                let xSpeed = (this.x2 - this.x1) / this.velocity;
                let ySpeed = (this.y2 - this.y1) / this.velocity;
                this.updateShape(this.x + xSpeed, this.y + ySpeed);
            }
        } else {
            if ((Math.round(this.x) === Math.round(this.x1)) && (Math.round(this.y) === Math.round(this.y1))) {
                this.toOther = true;
            } else {
                let xSpeed = (this.x1 - this.x2) / this.velocity;
                let ySpeed = (this.y1 - this.y2) / this.velocity;
                this.updateShape(this.x + xSpeed, this.y + ySpeed);
            }
        }
    }

    /**
     * Method to create a JSON Object out of a movable object.
     * @returns {*} object containing all the relevant fields needed to create a movable object
     */
    getJSON() {
        let jsonObject = super.getJSON();
        jsonObject.size = this.size;
        jsonObject.toReflect = this.toReflect;
        jsonObject.x1 = this.x1;
        jsonObject.y1 = this.y1;
        jsonObject.x2 = this.x2;
        jsonObject.y2 = this.y2;
        jsonObject.velocity = this.velocity;
        jsonObject.color = this.color;
        return jsonObject;
    }

    /**
     * Method to create a player from a JSON Object.
     * @param JSONMovable JSON Object containing a movable object
     * @param p5 p5 library
     * @returns {Movable} a Movable object
     */
    static createMovableFromJSON(JSONMovable, p5) {
        return new Movable(JSONMovable.x1, JSONMovable.y1, JSONMovable.x2, JSONMovable.y2, JSONMovable.velocity, JSONMovable.size, JSONMovable.shape, JSONMovable.toReflect, JSONMovable.color, p5);
    }

}

export default Movable;