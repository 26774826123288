class Style {

    constructor(lineWeight, lineColor, fillColor) {
        this.lWeight = lineWeight;
        this.lColor = lineColor;
        this.fColor = fillColor;
        this.shapeFill = true;
    }

    /**
     * Sets the style state to no-fill.
     */
    noFill() {
        this.shapeFill = false;
    }

    /**
     * Sets the style state to fill.
     */
    fill() {
        this.shapeFill =true;
    }

    /**
     * Sets the fill color of this style object
     * @param color The color the fill needs to have
     */
    fillColor(color) {
        this.fColor = color;
    }

    /**
     * Sets the line color of this style object
     * @param color The color the line should be
     */
    lineColor(color) {
        this.lColor = color;
    }

    /**
     * Sets the width of the lines.
     * @param weight the line width ( 1 is default )
     */
    lineWeight(weight) {
        this.lWeight = weight;
    }

    /**
     * Sets all the p5 render states.
     * @param p5 the p5 object to set the states for
     */
    setState(p5) {
        //Line states
        p5.stroke(this.lColor);
        p5.strokeWeight(this.lWeight);

        //Fill States
        if(this.shapeFill){
            p5.fill(this.fColor)
        } else {
            p5.noFill()
        }
    }

    styleToJson() {
        let json = {};

        json.lWeight = this.lWeight;
        json.lColor = this.lColor;
        json.fColor = this.fColor;
        json.shapeFill = this.shapeFill;

        return json;
    }

    static createStyleFromJson(JSONShape) {
        let style = new Style(JSONShape.lWeight, JSONShape.lColor, JSONShape.fColor)
        if (!JSONShape.shapeFill) {
            style.noFill()
        }

        return style;
    }

}

export default Style;