import PlayerRepository from "./repositories/playerRepository";
import LaserRepository from "./repositories/laserRepository";
import ShapeRepository from "./repositories/shapeRepository";
import GoalRepository from "./repositories/goalRepository";
import MovableRepository from "./repositories/movableRepository";

import Laser from "./entities/laser";
import Player from "./entities/player";
import Goal from "./entities/goal";
import Shape from "./entities/shape";
import Movable from "./entities/movable";
import "isomorphic-fetch";

/**
 * Creates the field object
 */
class Field {

    /**
     * Size should be in a 5:3 ratio
     * @param width the width of the field
     * @param height the height of the field
     * @param p5 the P5 library object
     */
    constructor(width, height, p5) {
        p5.createCanvas(width, height);
        p5.frameRate(60);
        this.source = p5.loadImage("/img/laser_source.png");
        this.width = width;
        this.height = height;

        this.p5 = p5;
        this.debug = false;

        this.players = new PlayerRepository();
        this.lasers = new LaserRepository();
        this.goals = new GoalRepository();
        //Doesn't include player shapes. for full list call getAllShapes()
        this.shapes = new ShapeRepository();
        this.movables = new MovableRepository();

        this.mouseIsBound = false;
        this.grid = false;
        this.completed = false;
        this.changeReflect = false;
        this.counter = null;

        // Sets a timeout to go back to the tutorial level
        this.resetTimeout = setTimeout(function () {
            this.p5.level.reset()
        }, 10000);
        this.timeOut = null;
    }

    /**
     * Method to get all the shapes of the goals and players
     * @returns an array of all the shapes {*[]}
     */
    getAllShapes() {
        let playerShapes = this.players.getShapes();
        let goalShapes = this.goals.getGoals();
        let movableShapes = this.movables.getMovables();

        return this.shapes.getShapes().concat(playerShapes.concat(goalShapes.concat(movableShapes)));
    }

    /**
     * Toggles the grid state
     */
    toggleGrid() {
        this.grid = !this.grid;
        this.shapes.toggleGrid();
    }

    /**
     * Removes a shape, goal or player from the field.
     * @param shape
     */
    removeSelected(shape) {
        this.players.remove(shape);
        this.goals.remove(shape);
        this.lasers.remove(shape);
        this.shapes.remove(shape);
        this.movables.remove(shape);
    }

    /**
     * Method to check if a grid needs to be rendered
     */
    checkAndRenderGrid() {
        if (this.grid) {
            this.renderGrid();
        }
    }

    /**
     * Activate the debugger
     */
    activateDebugger() {
        if (this.debug) {
            this.debug = false;
            debugger;
        }
    }

    /**
     * Method to render the field
     */
    render() {
        // Set the background color
        this.p5.background("white");

        this.checkAndRenderGrid();

        this.goals.reset();
        this.activateDebugger();

        if (!global.editor.editMode) {
            this.movables.activateMovables();
        }
        this.lasers.activateLasers(this);

        this.shapes.render();
        this.movables.render();
        this.goals.render();
        this.players.render();

        this.checkAndRenderWinText();

        this.resetTimer();
    }

    /**
     * Checks whether players are bound, if none are, we call the level reset function
     */
    resetTimer() {
        if (true) {
            clearTimeout(this.resetTimeout);
            this.resetTimeout = setTimeout(function () {
                this.p5.level.reset()
            }, 10000);
        }
    }

    /**
     * Checks if all goals are met, and if so, go to the next level and display a 3 second counter
     */
    checkAndRenderWinText() {
        // Check if a level is finished
        if (!global.editor.editMode) {
            if (this.goals.allGoalsHit() && !this.completed) {
                this.timeOut = setTimeout(this.p5.nextLevel, 4000);
                this.completed = true;
                this.counter = Date.now();
            }
            // If not held for 2 seconds, the timer resets
            if (this.completed && (this.counter - Date.now() >= -1000) && !this.goals.allGoalsHit()) {
                clearTimeout(this.timeOut);
                this.completed = false;
            }
            if (this.completed) {
                if (this.counter != null) {
                    let delta = this.counter - Date.now();
                    if (delta >= -3000) {
                        this.p5.fill("Orange");
                        this.p5.textSize(300);
                        this.p5.text(Math.round(delta / 1000) + 3, this.width / 2 - 100, this.height / 2 + 100);
                    } else {
                        this.p5.level.speed = -30;
                    }
                }
            }
        }
    }

    /**
     * Changes the rotation of a laser.
     * @param moveMode check if we are in move mode
     */
    renderRotationModifier(moveMode) {
        if (moveMode) {
            let field = this;
            this.lasers.elements.forEach(function (laser) {
                //render a rotation blob
                let x2 = laser.x + laser.direction[0] * 75;
                let y2 = laser.y + laser.direction[1] * 75;
                let size2 = 20;
                field.p5.image(field.source, x2 - (size2 / 2), y2 - (size2 / 2), size2, size2);
            });

            this.movables.elements.forEach(function (movable) {
                let x2 = movable.x2;
                let y2 = movable.y2;
                let size2 = 20;
                field.p5.image(field.source, x2 - (size2 / 2), y2 - (size2 / 2), size2, size2);
            });
        }
    }

    /**
     * Render the vertical lines of the grid
     */
    renderVerticals() {
        let wSpacing = this.width / 16;

        let x1 = 0;
        let x2 = 0;
        let y1 = 0;
        let y2 = this.height;

        for (let i = 0; i < 15; i++) {
            x1 += wSpacing;
            x2 = x1;
            this.p5.strokeWeight(2);
            this.p5.stroke('gray');
            this.p5.line(x1, y1, x2, y2);
        }
    }

    /**
     * Render the horizontal lines of the grid
     */
    renderHorizontals() {
        let hSpacing = this.height / 9;

        let x1 = 0;
        let x2 = this.width;
        let y1 = 0;
        let y2 = 0;

        for (let i = 0; i < 8; i++) {
            y1 += hSpacing;
            y2 = y1;
            this.p5.strokeWeight(2);
            this.p5.stroke('gray');
            this.p5.line(x1, y1, x2, y2);
        }
    }

    /**
     * Renders the grid
     */
    renderGrid() {
        this.renderVerticals();
        this.renderHorizontals();
    }

    /**
     * Method to start debugging
     */
    toDebug() {
        if (this.p5.keyIsDown("Z".charCodeAt(0))) {
            this.debug = true;
        }
    }

    /**
     * Method to create a JSON Object out of a player
     * @returns {*} object containing all the relevant fields needed to create a player
     */
    fieldToJSON() {
        let jsonObject = {};
        jsonObject.goals = this.goals.getJSON();
        jsonObject.height = this.height;
        jsonObject.lasers = this.lasers.getJSON();
        jsonObject.players = this.players.getJSON();
        jsonObject.shapes = this.shapes.getJSON();
        jsonObject.movables = this.movables.getJSON();
        jsonObject.width = this.width;
        return jsonObject;
    }

    /**
     * Method to create a level / field from a JSON object containing a field
     * @param JSONField JSON object containing a field
     * @param p5 the p5 library
     * @returns {Field} a Field object
     */
    static createFieldFromJSON(JSONField, p5) {
        let field = new Field(JSONField.width, JSONField.height, p5);
        JSONField.goals.forEach((goal) => field.goals.add(Goal.createGoalFromJSON(goal, p5)));
        JSONField.lasers.forEach((laser) => field.lasers.add(Laser.createLaserFromJSON(laser, p5)));
        JSONField.players.forEach((player) => {
            field.players.startingPlayers.push(Player.createPlayerFromJSON(player, p5))
        });
        JSONField.shapes.forEach((shape) => field.shapes.add(Shape.createShapeFromJSON(shape, p5)));
        JSONField.movables.forEach((movable) => field.movables.add(Movable.createMovableFromJSON(movable, p5)));
        return field;
    }

    /**
     * Save the level as json to the server.
     * @param name The name of the level
     */
    saveLevel(name) {
        let json = this.fieldToJSON();
        json.levelName = name;
        let res = fetch("/saveLevel", {
            body: JSON.stringify(json), // must match 'Content-Type' header
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, same-origin, *omit
            headers: {
                'user-agent': 'Mozilla/4.0 MDN Example',
                'content-type': 'application/json'
            },
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            redirect: 'follow', // manual, *follow, error
            referrer: 'no-referrer', // *client, no-referrer
        })
            .then(response => response.json());
        console.log(res);
    }

    mouseMovePlayers(x, y) {
        this.players.moveMouse(x, y);
    }

    /**
     * pass mouse movement to all objects
     * @param x pos
     * @param y pos
     */
    mouseMoveObjects(x, y) {
        this.goals.move(x, y);
        this.shapes.move(x, y);
        this.lasers.move(x, y);
        this.movables.move(x, y);
    }

    /**
     * Pass the key to all players
     */
    keyMovePlayers() {
        this.toDebug();
        this.players.keyMove();
    }

    /**
     * Method to bind the mouse to a player, or unbind it
     * @param x x-coordinate of the mouse
     * @param y y-coordinate of the mouse
     * @param {Boolean} moveMode if this is true, it allows to move all shapes.
     * @param {Boolean} removeMode if this is true, it allows to remove all shapes.
     * @param {Boolean} reflectMode if this is true, it allows to alter the reflective properties of shapes.
     */
    mouseClick(x, y, moveMode, removeMode, reflectMode) {
        this.changeReflect = reflectMode;
        if (this.mouseIsBound) {
            this.players.unbindMouse();
            this.goals.unbindMouse();
            this.shapes.unbindMouse();
            this.lasers.unbindMouse();
            this.movables.unbindMouse();
            this.mouseIsBound = false;
        } else if (this.changeReflect) {
            this.shapes.checkReflectAtCoordinates(x, y);
            this.movables.checkReflectAtCoordinates(x, y);
        } else {
            this.moveObjects(x, y, moveMode);
            this.removeObjects(x, y, removeMode);
        }
    }

    /**
     * allows to move an object once it is clicked
     * @param x x-coordinate of the mouse
     * @param y y-coordinate of the mouse
     * @param {Boolean} moveMode if this is true, it allows to move all shapes.
     */
    moveObjects(x, y, moveMode) {
        //Move Players
        if (this.players.bindMouse(x, y)) {
            this.mouseIsBound = true;
        }

        //Move Goals
        if (moveMode && this.mouseIsBound === false) {
            if (this.goals.bindMouse(x, y)) {
                this.mouseIsBound = true;
            }
        }

        //Move Shapes
        if (moveMode && this.mouseIsBound === false) {
            if (this.shapes.bindMouse(x, y)) {
                this.mouseIsBound = true;
            }
        }

        //Move lasers
        if (moveMode && this.mouseIsBound === false) {
            if (this.lasers.bindMouse(x, y)) {
                this.mouseIsBound = true;
            }
        }

        //Move movables
        if (moveMode && this.mouseIsBound === false) {
            if (this.movables.bindMouse(x, y)) {
                this.mouseIsBound = true;
            }
        }
    }

    /**
     * allows to remove an object once it is clicked
     * @param x x-coordinate of the mouse
     * @param y y-coordinate of the mouse
     * @param {Boolean} removeMode if this is true, it allows to move all shapes.
     */
    removeObjects(x, y, removeMode) {
        //Remove Shapes
        if (removeMode) {
            this.players.removeAtCoordinates(x, y);
            this.lasers.removeAtCoordinates(x, y);
            this.goals.removeAtCoordinates(x, y);
            this.shapes.removeAtCoordinates(x, y);
            this.movables.removeAtCoordinates(x, y);
        }
    }
}

export default Field;