import ArrayRepository from "./arrayRepository"
import Style from "../entities/style";

/**
 * Container for the Movable objects of the game
 */
class MovableRepository extends ArrayRepository {
    constructor() {
        super();
    }

    /**
     * Retrieve all shapes.
     * @returns {Array} shapes of each element in the repository
     */
    getMovables() {
        let movableShapes = [];

        this.elements.forEach((movable) => {
            movableShapes.push(movable);
        });

        return movableShapes;
    }

    /**
     * Move all the movable objects
     */
    activateMovables() {
        this.elements.forEach(function (element) {
            element.moveObject();
        });
    }

    /**
     * Find the first element that shares coordinates with the mouseclick and send it to another method to change its reflect.
     * @param x x-coordinate of mouse click
     * @param y y-coordinate of mouse click
     */
    checkReflectAtCoordinates(x, y) {
        for (let element of this.elements) {
            if (element.mouseHit(x, y)) {
                this.changeReflect(element);
                break;
            }
        }
    }

    /**
     * The actual method to change the reflect.
     * @param shape the shape to find and change
     */
    changeReflect(shape) {
        let index = this.elements.indexOf(shape);

        if (index >= 0) {
            this.elements[index].toReflect = !this.elements[index].toReflect;
            if (this.elements[index].toReflect) {
                this.elements[index].styleObject = new Style(5, "black", "black");
            } else {
                this.elements[index].styleObject = new Style(5, "black", "#a8a8a8");
            }
        }
    }

    /**
     * Allow to mouse move the objects.
     */
    mouseMove(x, y) {
        this.elements.forEach((element) => {
            element.move(x, y);
        });
    }

    /**
     * Unbind all elements in the array from the mouse.
     */
    unbindMouse() {
        this.elements.forEach((movable) => {
            if (movable.secondPointControl != null || movable.mouseControl) {
                movable.mouseControl = false;
                movable.secondPointControl = false;
            }
        });
    }
}

export default MovableRepository;