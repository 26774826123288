import Goal from "./goal";

class Laser {

    /**
     * Laser constructor
     * @param {Number} x
     * @param {Number} y
     * @param {Array} direction
     * @param {Object} p5
     */
    constructor(x, y, direction, p5) {
        this.x = x;
        this.y = y;
        this.p5 = p5;
        this.direction = direction;

        // Initializing the vector multiplier and the color
        this.vectorMultiplier = 10000;
        this.color = this.p5.color(255, 255, 0);
        this.mouseControl = false;
        this.laserControl = false;
    }

    /**
     * Method to calculate the ray from the laser, can be used recursively
     * @param objects all objects in the field
     * @param lines array with collision points of the laser and
     * the last vector, containing the direction of the laser from the last collision.
     * @returns lines
     */
    raytrace(objects, lines) {
        //build lines object (if needed)
        if (lines === null) {
            lines = {
                points: [],
            };
            //add origin points
            lines.points.push(this.p5.createVector(this.x, this.y));
        }
        // Set direction vector
        lines.lastVector = this.p5.createVector(this.direction[0], this.direction[1]);

        // Determine if the laser hits an object, if so dataObject contains the closest object that is hit
        let dataObject = this.determineClosestObject(objects);

        // Determine the reflection from the object that is hit
        lines = this.determineReflection(dataObject, objects, lines);
        return lines;
    }

    /**
     * Method to determine the reflection when the laser hits an object
     * @param dataObject the object that is hit by the laser.
     * @param objects all objects in the field.
     * @param lines object consisting of an array with all the collision points until now
     * and the laser direction from the last point
     * @returns {*} lines, an updated version of the parameter lines.
     * The object now contains the new intersection point and the new laser direction
     */
    determineReflection(dataObject, objects, lines) {
        // If dataObject is a goal, it should not reflect
        if (dataObject.closestObject instanceof Goal) {
            dataObject.closestObject.hit();
            lines.points.push(this.p5.createVector(dataObject.hit.x, dataObject.hit.y));
            lines.lastVector = this.p5.createVector(0, 0);
        } else {
            //Calculate reflection if there is an intersection, but not two objects or on a corner
            if (dataObject.hit !== null && !dataObject.twoIntersections) {
                // Create line that leaves the field in the direction of the laser, starting from the start location of the laser
                let x2 = this.x + this.direction[0] * this.vectorMultiplier;
                let y2 = this.y + this.direction[1] * this.vectorMultiplier;

                // Calculate the reflection vector
                let reflectionVector = this.calculateReflect(this.p5.createVector(this.x - x2, this.y - y2), dataObject.intersectionVector);
                lines.points.push(this.p5.createVector(dataObject.hit.x, dataObject.hit.y));

                // Recursive call, if there are less reflections than the threshold (100), the object reflects and the reflection is not in the same direction.
                if (lines.points.length < 100 && dataObject.closestObject.toReflect && !this.reflectBack(reflectionVector)) {
                    let newLaser = new Laser(dataObject.hit.x, dataObject.hit.y, [reflectionVector.x, reflectionVector.y], this.p5);
                    lines = newLaser.raytrace(objects, lines);
                    // If no recursive call took place, change the last vector to stop the reflection.
                } else if (!dataObject.closestObject.toReflect || this.reflectBack(reflectionVector)) {
                    lines.lastVector = this.p5.createVector(0, 0);
                }
            }

            // If there is an intersection, but with two objects or on a corner.
            else if (dataObject.hit !== null && dataObject.twoIntersections) {
                lines.points.push(this.p5.createVector(dataObject.hit.x, dataObject.hit.y));
                lines.lastVector = this.p5.createVector(0, 0);
            }
        }
        return lines;
    }

    /**
     * Method to determine the closest object to the laser
     * @param objects all objects in the field
     */
    determineClosestObject(objects) {
        // Create a basic object that is returned if no object is hit.
        let returnObject = {};
        returnObject.hit = null;
        // Initiate the distance to another object
        let distance = Number.MAX_SAFE_INTEGER;

        // For each object determine if the object is hit by the laser
        objects.forEach((object) => {
            // Extract the shape from the object
            let shape = object;

            // Determine the closest vertex
            // returnValue contains the following:
            // hit: the location where the object is hit, null if not hit
            // distance: the distance from the source of the laser to the
            // twoIntersections: boolean if 2 objects or a corner is hit
            // shape: the shape of the object
            // intersectionVector: the vector on which the laser reflects
            let returnValue = this.determineClosestVertex(shape, distance);

            // Only update the object if the distance is smaller
            if (returnValue.distance < distance) {
                distance = returnValue.distance;
                returnObject = returnValue;
                returnObject.closestObject = object;
                //If its intersecting with 2 objects update twoIntersection to prevent strange laser reflections
            } else if (Math.abs(returnValue.distance - distance) < 0.1) {
                returnObject.twoIntersections = true;
            }
        });
        return returnObject;
    }

    /**
     * Method to determine the closest vertex of a shape
     * @param shape, the shape of which the vertices are checked if the lasers hit them
     * @param distance, the shortest distance from the laser to a vertex before this shape was checked
     */
    determineClosestVertex(shape, distance) {
        // initiate the basic elements of the returnObject.
        // distance: the shortest distance between the laser and a vertex of this object. By default highest integer
        // shape: the current shape
        // hit: the location where the laser hits the shape. By default null
        // twoIntersections: if the closest vertex is a corner, or very close to another vertex
        let returnObject = {};
        returnObject.distance = Number.MAX_SAFE_INTEGER;
        returnObject.shape = shape;
        returnObject.hit = null;
        returnObject.twoIntersections = false;

        let vertices = shape.getVectors();
        let next = 0;
        // go through each of the vertices, plus the next vertex in the list
        for (let current = 0; current < vertices.length; current++) {

            // get next vertex in list if we've hit the end, wrap around to 0
            next = current + 1;
            if (next === vertices.length) {
                next = 0;
            }

            // Create line that leaves the field in the direction of the laser, starting from the start location of the laser
            let x2 = this.x + this.direction[0] * this.vectorMultiplier;
            let y2 = this.y + this.direction[1] * this.vectorMultiplier;

            // get the PVectors at our current position extract X/Y coordinates from each
            let x3 = vertices[current].x;
            let y3 = vertices[current].y;
            let x4 = vertices[next].x;
            let y4 = vertices[next].y;

            // do a Line/Line comparison if true, return 'true' immediately and stop testing (faster)
            let hit = this.p5.collideLineLine(this.x, this.y, x2, y2, x3, y3, x4, y4, true);
            //If we have an intersection
            if (hit.x !== false) {
                returnObject = this.laserHit(hit, returnObject, this.p5.createVector(x4 - x3, y4 - y3));
            }
        }
        return returnObject;
    }

    /**
     * Method to update a hit object
     * @param hit, the location where the object was hit
     * @param object, the object that is hit
     * @param vectorIntersect, the vertex of the object where the laser intersects with
     * @returns {*} object, an updated version of the param object, containing the hit location, updated twoIntersections and the distance.
     */
    laserHit(hit, object, vectorIntersect) {
        // Round the hit location to at most 2 decimals.
        hit.x = Math.round(hit.x * 100) / 100;
        hit.y = Math.round(hit.y * 100) / 100;
        //Check if it is closer than the previous intersection
        let newDistance = this.p5.createVector(this.x, this.y).dist(this.p5.createVector(hit.x, hit.y));
        if (newDistance < object.distance && newDistance > 0.05) {
            // check if it is just closer, or hit is a corner.
            if (Math.abs(newDistance - object.distance) <= 0.01 || object.shape.checkPoints(hit.x, hit.y)) {
                object.twoIntersections = true;
            } else {
                object.twoIntersections = false;
            }

            //update object information
            object.intersectionVector = vectorIntersect;
            object.hit = hit;
            object.distance = newDistance;
        }
        // Check if the hit location is just larger than the shortest distance
        // This is to prevent a weird reflection
        else if (Math.abs(newDistance - object.distance) <= 0.01) {
            object.twoIntersections = true;
        }

        return object;
    }

    /**
     * Method to check if the reflection vector reflects back in the same direction it came from.
     * @param reflectionVector the reflection vector
     * @returns {boolean} true if it goes back in the same direction, else false
     */
    reflectBack(reflectionVector) {
        // Normalize both the laser vector and the reflection vector so they are both equally long
        let originalVector = this.p5.createVector(this.direction[0], this.direction[1]).normalize();
        reflectionVector.normalize();

        // Compare the rounded x and y of both vectors to check if they are equal
        if ((Math.round(reflectionVector.x * 100) / 100) === -(Math.round(originalVector.x * 100) / 100)
            && (Math.round(reflectionVector.y * 100) / 100) === -(Math.round(originalVector.y * 100) / 100)) {
            return true;
        }
        return false;
    }

    /**
     * Method to calculate the reflection of a vector
     * @param laserVector vector of the laser
     * @param intersectionVector object with collision point and the vector of that line
     * @returns {p5.Vector} reflection vector
     */
    calculateReflect(laserVector, intersectionVector) {
        let nVector = intersectionVector;
        // create the vector perpendicular to the intersectionVector
        let nY = -nVector.x;
        let nX = nVector.y;
        let perpendicularN = this.p5.createVector(nX, nY);
        let n = perpendicularN.normalize();
        let d = laserVector.normalize();
        return this.calculateReflectionVector(d, n);
    }

    /**
     * Method to detect if mouse hit the laser
     * @param x coordinate of the mouse
     * @param y coordinate of the mouse
     * @returns {boolean}
     */
    mouseHit(x, y) {
        if (Math.abs(this.x - x) <= 25) {
            if (Math.abs(this.y - y) <= 25) {
                this.mouseControl = true;
                return true;
            }
        }

        let x2 = this.x + this.direction[0] * 75;
        let y2 = this.y + this.direction[1] * 75;

        if (Math.abs(x2 - x) <= 10) {
            if (Math.abs(y2 - y) <= 10) {
                this.laserControl = true;
                return true;
            }
        }

        return false;
    }

    /**
     * Method to move the laser
     * @param x coordinate to move too
     * @param y coordinate to move too
     */
    move(x, y) {
        if (this.mouseControl && typeof x !== "undefined" && typeof  y !== "undefined") {
            this.update(x, y)
        } else if (this.laserControl && typeof x !== "undefined" && typeof  y !== "undefined") {
            this.updateLaserRotation(x, y)
        }
    }

    /**
     * Method to update the position of a laser
     * @param x the new x-coordinate of the laser
     * @param y the new y-coordinate of the laser
     */
    update(x, y) {
        this.x = x;
        this.y = y;
    }

    /**
     * Method to update the rotation of a laser
     * @param x the new x-coordinate of the rotation
     * @param y the new y-coordinate of the rotation
     */
    updateLaserRotation(x, y) {
        let angleDeg = Math.atan2(y - this.y, x - this.x);
        this.direction[0] = Math.round(Math.cos(angleDeg) * 10000) / 10000;
        this.direction[1] = Math.round(Math.sin(angleDeg) * 10000) / 10000;
    }

    /**
     * Calculate the reflection vector from the normal and the incoming vectors
     * @param d incoming vector
     * @param n normal vector
     * @returns {p5.Vector} reflection vector
     */
    calculateReflectionVector(d, n) {
        let dot = (d.x * n.x + d.y * n.y);
        let r1 = Math.round((2 * dot * n.x - d.x) * 100) / 100;
        let r2 = Math.round((2 * dot * n.y - d.y) * 100) / 100;
        let r = this.p5.createVector(r1, r2);
        return r.normalize();
    }

    /**
     * Method to create a JSON Object out of a laser
     * @returns {*} object containing all the relevant fields needed to re-create a laser
     */
    getJSON() {
        let jsonObject = {};
        jsonObject.direction = this.direction;
        jsonObject.x = this.x;
        jsonObject.y = this.y;
        return jsonObject;
    }

    /**
     * Method to turn a JSON object containing a laser into a Laser object
     * @param JSONLaser JSON object containing a laser
     * @param p5 the p5 library
     * @returns {Laser} a Laser object
     */
    static createLaserFromJSON(JSONLaser, p5) {
        return new Laser(JSONLaser.x, JSONLaser.y, JSONLaser.direction, p5);
    }

}

export default Laser;