import "../../../libs/addons/p5.collide2d";
import "../../../libs/addons/p5.sound";
import "../../../libs/addons/p5.dom";
import Field from "./field";
import Editor from "./editor";
import Level from "./level";
import CMenu from "../../../libs/circular-menu";

const main = (p5) => {
    "use strict";
    let cnv;
    let field;
    let level;
    let isSetup = false;
    let startLevel = "simple2";


    window.p5 = p5;

    function centerCanvas() {
        let x = 0;
        let y = 0;
        cnv.position(x, y);

        if (global.editor) {
            global.editor.setMenuOffset(x, y);
        }
    }

    /**
     * Native setup function.
     */
    p5.setup = () => {
        // Initialize the field
        field = new Field(p5.windowWidth, p5.windowHeight, p5);
        global.editor = new Editor(field, startLevel, new CMenu("#editorMenu"));

        level = new Level(p5);

        p5.loadLevelOrder();

        //buttonSetup();

        cnv = p5.createCanvas(p5.windowWidth, p5.windowHeight);
        centerCanvas();
        cnv.elt.oncontextmenu = () => {
            return false
        };

        p5.field = field;
        isSetup = true;
    };

    /**
     * Resize the canvas
     */
    p5.windowResized = () => {
        centerCanvas();
    };

    /**
     * Load in a level
     * @param name level name to load
     */
    p5.loadLevel = (name) => {
        fetch("/loadLevel", {
            body: JSON.stringify({levelName: name}),
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'user-agent': 'Mozilla/4.0 MDN Example',
                'content-type': 'application/json'
            },
            method: 'POST',
            mode: 'cors',
            redirect: 'follow',
            referrer: 'no-referrer',
        })
            .then(response => response.json())
            .catch(error => console.error('Error:', error))
            .then(response => changeField(response));
    };

    /**
     * Change the field to a stored field
     * @param jsonField JSON object of a field
     */
    let changeField = (jsonField) => {
        if (typeof jsonField.loadError === "undefined") {
            p5.noLoop();
            clearTimeout(p5.field.resetTimeout);
            field = Field.createFieldFromJSON(jsonField, p5);
            global.editor.field = field;
            global.editor.levelName = jsonField.levelName;
            p5.field = field;
            p5.loop();
        } else {
            alert("level doesn't exist")
        }
    };

    /**
     * Load the level order
     */
    p5.loadLevelOrder = () => {
        fetch("/loadLevelOrder", {
            body: JSON.stringify({levelName: "levels"}),
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'user-agent': 'Mozilla/4.0 MDN Example',
                'content-type': 'application/json'
            },
            method: 'POST',
            mode: 'cors',
            redirect: 'follow',
            referrer: 'no-referrer',
        })
            .then(response => response.json())
            .catch(error => console.error('Error:', error))
            .then(response => changeLevel(response));
    };

    /**
     * Change the level to a JSON Field
     * @param jsonField field to change the level too
     */
    let changeLevel = (jsonField) => {
        if (typeof jsonField.loadError === "undefined") {
                p5.noLoop();
                level = Level.createLevelFromJSON(jsonField, p5);
                p5.level = level;
                p5.nextLevel();
                p5.loop();
        } else {
            alert("level doesn't exist")
        }
    };

    /**
     * Go to the next level
     */
    p5.nextLevel = () => {
        if (!global.editor.editMode) {
            p5.level.nextLevel();
        }
    };

    /**
     * Native draw function.
     * called 60 time per second
     */
    p5.draw = () => {
        field.keyMovePlayers();
        field.renderRotationModifier(global.editor.inMoveMode());
        field.render();
        level.render();
    };

    /**
     * Key released event
     */
    p5.keyReleased = () => {
        if (p5.key == "M"){
            global.editor.editMode = !global.editor.editMode;
            console.log("Edit Mode: " + global.editor.editMode);
        }
    };

    /**
     * mouse move event
     * @returns {boolean}
     */
    p5.mouseMoved = () => {
        if (isSetup) {
            field.mouseMovePlayers(p5.mouseX, p5.mouseY);
            if (global.editor.editMode && global.editor.inMoveMode()) {
                field.mouseMoveObjects(p5.mouseX, p5.mouseY);
            }
        }
        // prevent default
        return false;
    };

    /**
     * mouse click event
     * @returns {boolean}
     */
    p5.mouseReleased = () => {
        if (isSetup) {
            if (global.editor.editMode) {
                if (p5.mouseButton === "left") {
                    field.mouseClick(p5.mouseX, p5.mouseY, global.editor.inMoveMode(), global.editor.inRemoveMode(), global.editor.inReflectMode());
                } else {
                    global.editor.menuHandler(p5.mouseX, p5.mouseY)
                }
            } else {
                field.mouseClick(p5.mouseX, p5.mouseY, false, false);
            }
        }
        // prevent default
        return false;
    };

};

export default main;
