import Shape from "./shape";
import Style from "./style";

class Goal extends Shape {

    /**
     * Constructor of goal
     * @param x x-coordinate of the goal
     * @param y y-coordinate of the goal
     * @param size the size of the goal
     * @param p5 the P5 library object
     */
    constructor(x, y, size, p5) {
        super(x, y, {shape: "hexagon", type: "flat"}, new Style(2, "gray", "rgb(" + 255 + ",0,0)"), p5, size);

        // Boolean if the goal is hit
        this.isHit = false;

        // Initializing the color
        this.colorCode = 255;
        this.descending = true;

        // Default shape of the goal is a hexagon
        this.changeReflect();
        this.notGoal = false;
        this.notGoalShape = new Shape(this.x, this.y, {shape: "plus"}, new Style(2, "black", "white"), this.p5, 100);
        this.notGoalShape.rotation = Math.PI / 4.0;
    }

    /**
     * Method to update the color of the goal
     */
    updateColorCode() {
        if (this.descending) {
            this.colorCode -= 2;
            if (this.colorCode <= 200) {
                this.descending = false;
            }
        } else {
            this.colorCode += 2;
            if (this.colorCode >= 253) {
                this.descending = true;
            }
        }
    }

    /**
     * Method to get the current color of the goal
     */
    getColorGoal() {
        if (this.isHit) {
            this.styleObject.fillColor("rgb(0," + this.colorCode + ",0)");
        } else {
            this.styleObject.fillColor("rgb(" + this.colorCode + ",50,50)");
        }
    }

    /**
     * Method to get the current color of the not goal
     */
    getColorNotGoal() {
        if (!this.isHit) {
            this.styleObject.fillColor("rgb(0," + this.colorCode + ",0)");
        } else {
            this.styleObject.fillColor("rgb(" + this.colorCode + ",50,50)");
        }
    }

    /**
     * Method to render a goal
     */
    render() {
        // If the goal is hit, render green. Else render it in red\
        if (!this.notGoal) {
            this.getColorGoal();
        } else {
            this.getColorNotGoal();
        }
        this.updateColorCode();

        // render the shape itself
        super.render();
        if (this.notGoal) {
            this.notGoalShape.renderRotate();
        }

    }

    /**
     * Method to reset a goal, change the boolean isHit to false
     */
    reset() {
        this.isHit = false;
    }

    /**
     * Method to indicate that a goal is hit, change the boolean isHit to true
     */
    hit() {
        this.isHit = true;
    }

    /**
     * Method to create a JSON Object out of a goal
     * @returns {*} object containing all the relevant fields needed to create a goal
     */
    getJSON() {
        let jsonObject = {};
        jsonObject.notGoal = this.notGoal;
        jsonObject.size = this.size;
        jsonObject.x = this.x;
        jsonObject.y = this.y;
        jsonObject.points = [];
        this.points.forEach((point) => jsonObject.points.push({x: point.x, y: point.y}));
        return jsonObject;
    }

    /**
     * Move the player based on mouse coordinates.
     * @param {Number} x cor
     * @param {Number} y cor
     */
    move(x, y) {
        if (this.mouseControl && typeof x !== "undefined" && typeof  y !== "undefined") {
            super.update(x, y);
            if (this.notGoal) {
                this.notGoalShape.update(x, y);
            }
        }
    }

    /**
     * Method to indicate if a player is hit by the mouse, so that is can be bound to the mouse
     * @param x x-coordinate of the mouse click
     * @param y y-coordinate of the mouse click
     * @returns {boolean} if it hit a player, true, else false
     */
    mouseHit(x, y) {
        if (Math.abs(this.x - x) <= 0.25 * this.size && Math.abs(this.y - y) <= this.size * 0.25) {
            this.mouseControl = true;
            return true;
        }
        return false;
    }

    /**
     * Method to turn a JSON object containing a goal into a Goal object
     * @param JSONGoal JSON object containing a goal
     * @param p5 the p5 library
     * @returns {Goal} a goal Object
     */
    static createGoalFromJSON(JSONGoal, p5) {
        let goal = new Goal(JSONGoal.x, JSONGoal.y, JSONGoal.size, p5);
        goal.notGoal = JSONGoal.notGoal;
        return goal;
    }
}

export default Goal;