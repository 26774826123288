import Goal from "./entities/goal";
import Player from "./entities/player";
import Shape from "./entities/shape";
import Laser from "./entities/laser";
import Style from "./entities/style";
import Movable from "./entities/movable";

class Editor {

    /**
     * Creates an editor object.
     * @param field the field to edit
     * @param name the name of the level
     * @param cmenu The CMenu library
     */
    constructor(field, name, cmenu) {
        let editor = this;
        this.editMode = false;
        this.xOffset = 0;
        this.yOffset = 0;
        this.levelName = name;
        this.field = field;
        this.moveMode = false;
        this.removeMode = false;
        this.reflectOn = false;

        //Contains the editor menu layout and functions
        this.menuConfig = {
            totalAngle: 180,
            position: "top",
            hideAfterClick: false,
            menus: [{
                title: "edit",
                icon: ["fas fa-edit", '#4078c0'],
                hideAfterClick: true,
                menus: [{
                    title: "rename", icon: ["fas fa-pencil-alt", '#4078c0'], click: () => {
                        Editor.textOption("enter a new name", editor.levelName, (input) => {
                            editor.changeName(input);
                            editor.menu.hide();
                            editor.menuOpen = false;
                        });
                    }
                }, {
                    title: "save", icon: ["far fa-save", '#4078c0'], click: () => {
                        editor.save();
                        editor.menu.hide();
                        editor.menuOpen = false;
                    }
                }, {
                    title: "save As", icon: ["fas fa-save", '#4078c0'], click: () => {
                        Editor.textOption("save as:", editor.levelName, (input) => {
                            editor.saveAs(input);
                            editor.menu.hide();
                            editor.menuOpen = false;
                        });
                    }
                }, {
                    title: "reset", icon: ["fas fa-undo-alt", '#4078c0'], click: () => {
                        let conf = confirm("are you sure you want to reset? There is no going back.");
                        if (conf === true) {
                            editor.reset();
                        }
                        editor.menu.hide();
                        editor.menuOpen = false;
                    }
                }, {
                    title: "load", icon: ["fas fa-download", '#4078c0'], click: () => {
                        Editor.textOption("level to load:", editor.levelName, (input) => {
                            editor.load(input);
                            editor.menu.hide();
                            editor.menuOpen = false;
                        });
                    }
                }, {
                    title: "moveMode", icon: ["fas fa-arrows-alt", editor.moveModeIcon()], click: function () {
                        editor.toggleMoveMode();
                        this.parentElement.nextSibling.children[0].children[0].children[0].style.color = editor.removeModeIcon();
                        this.parentElement.nextSibling.nextSibling.children[0].children[0].children[0].style.color = editor.reflectModeIcon();
                        this.children[0].children[0].style.color = editor.moveModeIcon();

                        editor.menu.hide();
                        editor.menuOpen = false;
                    }
                }, {
                    title: "removeMode", icon: ["fas fa-trash-alt", editor.removeModeIcon()], click: function () {
                        editor.toggleRemoveMode();
                        this.parentElement.previousSibling.children[0].children[0].children[0].style.color = editor.moveModeIcon();
                        this.parentElement.nextSibling.children[0].children[0].children[0].style.color = editor.reflectModeIcon();
                        this.children[0].children[0].style.color = editor.removeModeIcon();

                        editor.menu.hide();
                        editor.menuOpen = false;
                    }
                    }, {
                    title: "reflectMode", icon: ["fas fa-check", editor.reflectModeIcon()], click: function () {
                        editor.toggleReflectMode();
                        this.parentElement.previousSibling.children[0].children[0].children[0].style.color = editor.removeModeIcon();
                        this.parentElement.previousSibling.previousSibling.children[0].children[0].children[0].style.color = editor.moveModeIcon();
                        this.children[0].children[0].style.color = editor.reflectModeIcon();

                        editor.menu.hide();
                        editor.menuOpen = false;
                    }
                    }, {
                    title: "Toggle grid",
                    icon: ["far fa-th", '#4078c0'],
                    click: () => {
                        editor.field.toggleGrid();
                    }
                }]
            }, {
                title: "AddPlayer",
                icon: ["fas fa-user-plus", '#4078c0'],
                menus: [{
                    title: "square", icon: ["far fa-square", '#4078c0'], click: function () {
                        editor.createPlayer(editor, {shape:"rectangle", type: ""});
                    }
                }, {
                    title: "diamond", icon: ["far fa-diamond", '#4078c0'], click: () => {
                        editor.createPlayer(editor, {shape:"diamond", type: ""});
                    }
                }]
                }, {
                title: "Add Shape",
                icon: ["far fa-object-ungroup", '#4078c0'],
                menus: [{
                    title: "square", icon: ["far fa-square", '#4078c0'], click: () => {
                        editor.createShape(editor, "rectangle", "");
                    }
                }, {
                    title: "diamond", icon: ["far fa-diamond", '#4078c0'], click: () => {
                        editor.createShape(editor, "diamond", "");
                    }
                }, {
                    title: "triangle", icon: ["far fa-triangle", '#4078c0'], menus: [
                        {
                            title: "left",
                            icon: ["far fa-chevron-left", '#4078c0'],
                            click: () => {
                                editor.createShape(editor, "triangle", "left");
                            }
                        },
                        {
                            title: "up",
                            icon: ["far fa-chevron-up", '#4078c0'],
                            click: () => {
                                editor.createShape(editor, "triangle", "up");
                            }
                        },
                        {
                            title: "down",
                            icon: ["far fa-chevron-down", '#4078c0'],
                            click: () => {
                                editor.createShape(editor, "triangle", "down");
                            }
                        },
                        {
                            title: "right",
                            icon: ["far fa-chevron-right", '#4078c0'],
                            click: () => {
                                editor.createShape(editor, "triangle", "right");
                            }
                        }

                    ]
                },
                    {
                        title: "plus",
                        icon: ["fas fa-plus", '#4078c0'],
                        click: () => {
                            editor.createShape(editor, "plus", "");
                        }
                    },
                    {
                        title: "hexagon",
                        icon: ["far fa-hexagon", '#4078c0'],
                        menus: [
                            {
                                title: "pointy",
                                icon: ["far fa-angle-up", '#4078c0'],
                                click: () => {
                                    editor.createShape(editor, "hexagon", "point");
                                }
                            },
                            {
                                title: "flat",
                                icon: ["far fa-ruler-horizontal", '#4078c0'],
                                click: () => {
                                    editor.createShape(editor, "hexagon", "flat");
                                }
                            }
                        ]
                    },
                    {
                        title: "arrow",
                        icon: ["fal fa-arrow-alt-right", '#4078c0'],
                        menus: [
                            {
                                title: "left",
                                icon: ["far fa-chevron-left", '#4078c0'],
                                click: () => {
                                    editor.createShape(editor, "arrow", "left");
                                }
                            },
                            {
                                title: "up",
                                icon: ["far fa-chevron-up", '#4078c0'],
                                click: () => {
                                    editor.createShape(editor, "arrow", "up");
                                }
                            },
                            {
                                title: "down",
                                icon: ["far fa-chevron-down", '#4078c0'],
                                click: () => {
                                    editor.createShape(editor, "arrow", "down");
                                }
                            },
                            {
                                title: "right",
                                icon: ["far fa-chevron-right", '#4078c0'],
                                click: () => {
                                    editor.createShape(editor, "arrow", "right");
                                }
                            }

                        ]
                    }
                ]
            }, {
                title: "Add Movable",
                icon: ["fal fa-arrows-h", '#4078c0'],
                menus: [{
                    title: "square", icon: ["far fa-square", '#4078c0'], click: () => {
                        editor.createMovable(editor, {shape:"rectangle", type: ""});
                    }
                }, {
                    title: "diamond", icon: ["far fa-diamond", '#4078c0'], click: () => {
                        editor.createMovable(editor, {shape:"diamond", type: ""});
                    }
                }, {
                    title: "triangle",
                    icon: ["far fa-triangle", '#4078c0'],
                    menus: [{
                        title: "left", icon: ["far fa-chevron-left", '#4078c0'], click: () => {
                            editor.createMovable(editor, {shape:"triangle", type: "left"});
                        }
                    }, {
                        title: "up", icon: ["far fa-chevron-up", '#4078c0'], click: () => {
                            editor.createMovable(editor, {shape:"triangle", type: "up"});
                        }
                    }, {
                        title: "down", icon: ["far fa-chevron-down", '#4078c0'], click: () => {
                            editor.createMovable(editor, {shape:"triangle", type: "down"});
                        }
                    }, {
                        title: "right", icon: ["far fa-chevron-right", '#4078c0'], click: () => {
                            editor.createMovable(editor, {shape:"triangle", type: "right"});
                        }
                    }]
                }, {
                    title: "plus", icon: ["fas fa-plus", '#4078c0'], click: () => {
                        editor.createMovable(editor, {shape:"plus", type: ""});
                    }
                }, {
                    title: "hexagon",
                    icon: ["far fa-hexagon", '#4078c0'],
                    menus: [{
                        title: "pointy", icon: ["far fa-angle-up", '#4078c0'], click: () => {
                            editor.createMovable(editor, {shape:"hexagon", type: "point"});
                        }
                    }, {
                        title: "flat", icon: ["far fa-ruler-horizontal", '#4078c0'], click: () => {
                            editor.createMovable(editor, {shape:"triangle", type: "flat"});
                        }
                    }]
                }, {
                    title: "arrow",
                    icon: ["fal fa-arrow-alt-right", '#4078c0'],
                    menus: [{
                        title: "left", icon: ["far fa-chevron-left", '#4078c0'], click: () => {
                            editor.createMovable(editor, {shape:"arrow", type: "left"});
                        }
                    }, {
                        title: "up", icon: ["far fa-chevron-up", '#4078c0'], click: () => {
                            editor.createMovable(editor, {shape:"arrow", type: "up"});
                        }
                    }, {
                        title: "down", icon: ["far fa-chevron-down", '#4078c0'], click: () => {
                            editor.createMovable(editor, {shape:"arrow", type: "down"});
                        }
                    }, {
                        title: "right", icon: ["far fa-chevron-right", '#4078c0'], click: () => {
                            editor.createMovable(editor, {shape:"arrow", type: "right"});
                        }
                    }]
                }],
            }, {
                title: "Add Goal",
                icon: ["fas fa-bullseye", '#4078c0'],
                menus: [
                    {
                        title: "default",
                        icon: ["fas fa-bullseye", '#c0141a'],
                        click: () => {
                            editor.toggleCreateObjectMode();
                            Editor.textOption("size", "125", (input) => {
                                if (!isNaN(input)) {
                                    let goal = new Goal(editor.menuX, editor.menuY, Number(input), editor.field.p5);
                                    editor.field.goals.add(goal);
                                } else {
                                    alert("not a number");
                                }

                            editor.menu.hide();
                            editor.menuOpen = false;
                        });
                    }
                    }, {
                    title: "reverse", icon: ["fas fa-bullseye", '#44c033'], click: () => {
                        editor.toggleCreateObjectMode();
                        Editor.textOption("size", "125", (input) => {
                            if (!isNaN(input)) {
                                let goal = new Goal(editor.menuX, editor.menuY, Number(input), editor.field.p5);
                                goal.notGoal = true;
                                editor.field.goals.add(goal);
                            } else {
                                alert("not a number");
                            }
                        });
                    }
                }]
            }, {
                title: "Add Laser", icon: ["fas fa-map-pin", '#4078c0'], click: () => {
                    editor.toggleCreateObjectMode();
                    let laser = new Laser(editor.menuX, editor.menuY, [0.5, 0.5], editor.field.p5);
                    editor.field.lasers.add(laser);

                    editor.menu.hide();
                    editor.menuOpen = false;
                }
            }, {
                title: "Player", icon: ["far fa-diamond", '#4078c0'], click: function() {
                    editor.toggleCreateObjectMode();

                    switch (editor.field.playerShape) {
                        case "diamond":
                            editor.field.playerShape = "rectangle";
                            this.children[0].children[0].className ="far fa-square cm-icon";
                            editor.field.players.changeShape("rectangle", editor.field.p5);
                            break;
                        case "rectangle":
                            editor.field.playerShape = "diamond";
                            this.children[0].children[0].className = "far fa-diamond cm-icon";
                            editor.field.players.changeShape("diamond", editor.field.p5);
                            break;
                    }

                }
            }]
        };

        this.menu = cmenu.config(this.menuConfig);
        this.menuOpen = false;
    }

    /**
     * changes the save name of the level.
     * @param {String} name the name it needs to be
     */
    changeName(name) {
        this.levelName = name;
        console.log(name);
    }

    /**
     * saves the level using its current name.
     */
    save() {
        let json = this.field.fieldToJSON();
        json.levelName = this.levelName;
        let res = fetch("/saveLevel", {
            body: JSON.stringify(json), // must match 'Content-Type' header
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, same-origin, *omit
            headers: {
                'user-agent': 'Mozilla/4.0 MDN Example',
                'content-type': 'application/json'
            },
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            redirect: 'follow', // manual, *follow, error
            referrer: 'no-referrer', // *client, no-referrer
        })
            .then(response => response.json());
    }

    /**
     * resets the level by loading the last save of name that is set in editor.
     */
    reset() {
        this.field.p5.loadLevel(this.levelName);
    }

    /**
     * changes name and saves it under the new name.
     * @param {String} name the name to save it as
     */
    saveAs(name) {
        this.changeName(name);
        this.save();
    }

    /**
     * changes name and loads that level.
     * @param {String} name the name of the level to load
     */
    load(name) {
        this.changeName(name);
        this.reset();
    }

    /**
     * get if the game is in move mode.
     * @returns {boolean} if the game is in move mode
     */
    inMoveMode() {
        return this.moveMode;
    }

    /**
     * get if the game is in remove mode.
     * @returns {boolean} if the game is in remove mode
     */
    inRemoveMode() {
        return this.removeMode;
    }

    /**
     * get if the game is in reflect mode.
     * @returns {boolean} if the game is in reflect mode
     */
    inReflectMode() {
        return this.reflectOn;
    }

    /**
     * sets the offset for the menu display.
     * @param x
     * @param y
     */
    setMenuOffset(x, y) {
        this.xOffset = x;
        this.yOffset = y;
    }

    /**
     * Toggles move mode on and off.
     */
    toggleMoveMode() {
        this.moveMode = !this.moveMode;
        this.removeMode = false;
        this.reflectOn = false;
    }

    /**
     * Toggles remove mode on and off.
     */
    toggleRemoveMode() {
        this.removeMode = !this.removeMode;
        this.moveMode = false;
        this.reflectOn = false;
    }

    /**
     * Toggles remove mode on and off.
     */
    toggleReflectMode() {
        this.reflectOn = !this.reflectOn;
        this.moveMode = false;
        this.removeMode = false;
    }

    /**
     * Turns off all the modes when creating an object.
     */
    toggleCreateObjectMode() {
        // let papaElement = $(".circular-sub-menu.circular-menu.s-S8.s-S9.s-S10.s-S11.s-S12");
        this.moveMode = true;
        this.removeMode = false;
        this.reflectOn = false;
        this.setColorsOfMenu();
    }

    /**
     * Toggles the colors of the different modes correctly
     */
    setColorsOfMenu() {
        let papaElement = document.getElementsByClassName("circular-sub-menu circular-menu s-S8 s-S9 s-S10 s-S11 s-S12")[0];
        papaElement.children[0].children[5].children[0].children[0].children[0].style.color = this.moveModeIcon();
        papaElement.children[0].children[6].children[0].children[0].children[0].style.color = this.removeModeIcon();
        papaElement.children[0].children[7].children[0].children[0].children[0].style.color = this.reflectModeIcon();
    }

    /**
     * Changes color depending whether move mode is on or off
     * @return {string} the color string
     */
    moveModeIcon() {
        if (this.moveMode) {
            return '#4078c0';
        } else {
            return '#c0222c';
        }
    }

    /**
     * Changes color depending whether remove mode is on or off
     * @return {string} the color string
     */
    removeModeIcon() {
        if (this.removeMode) {
            return '#4078c0';
        } else {
            return '#c0222c';
        }
    }

    /**
     * Changes color depending whether reflect mode is on or off
     * @return {string} the color string
     */
    reflectModeIcon() {
        if (this.reflectOn) {
            return '#4078c0';
        } else {
            return '#c0222c';
        }
    }

    /**
     * Allows to create another player on the field.
     * @param editor the editor in use
     * @param shape What shape the player should for to
     */
    createPlayer(editor, shape) {
        editor.toggleCreateObjectMode();
        Editor.textOption("size", "225", (input) => {
            if (!isNaN(input)) {
                let player = new Player(editor.menuX, editor.menuY, Number(input), [], shape, editor.field.p5);
                editor.field.players.startingPlayers.push(player);
            } else {
                alert("not a number");
            }

            editor.menu.hide();
            editor.menuOpen = false;
        });
    }

    /**
     * Allows to create another movable object on the field.
     * @param editor the editor in use
     * @param shape What shape the movable object should use
     */
    createMovable(editor, shape) {
        Editor.textOption("size", "75", (size) => {
            if (!isNaN(size)) {
                let conf = confirm("does this shape reflect?");
                let color = "black";
                if (!conf) {
                    color = "#a8a8a8";
                }

                Editor.textOption("What is the anti-velocity of the object? (higher is slower)", "20", (velocity) => {
                    if (!isNaN(velocity)) {
                        Editor.textOption("Determine the x-axis movement", "50", (xMod) => {
                            if (!isNaN(xMod)) {
                                Editor.textOption("Determine the y-axis movement", "50", (yMod) => {
                                    if (!isNaN(yMod)) {

                                        let movable = new Movable(editor.menuX, editor.menuY, editor.menuX + Number(xMod), editor.menuY + Number(yMod), Number(velocity), Number(size), shape, conf, color, editor.field.p5);
                                        editor.field.movables.add(movable);
                                    } else {
                                        alert("not a number");
                                    }
                                });
                            } else {
                                alert("not a number");
                            }
                        });
                    } else {
                        alert("not a number");
                    }
                });
            } else {
                alert("not a number");
            }

            editor.menu.hide();
            editor.menuOpen = false;
        });
    }

    /**
     * Allows to create another shape on the field.
     * @param editor the editor in use
     * @param shape the shape needed
     * @param type the type the shape should be
     */
    createShape(editor, shape, type) {
        editor.toggleCreateObjectMode();
        Editor.textOption("size", "150", (size) => {
            if (!isNaN(size)) {
                let conf = confirm("does this shape reflect?");
                let color = "black";
                if (conf === false) {
                    color = "#a8a8a8";
                }

                let style = new Style(5, "black", color);

                let shapeObject = new Shape(editor.menuX, editor.menuY, {shape: shape, type: type}, style, editor.field.p5, Number(size))

                if (conf === false) {
                    shapeObject.changeReflect();
                }
                shapeObject.grid = editor.field.grid;
                editor.field.shapes.add(shapeObject);
            } else {
                alert("not a number");
            }

            editor.menu.hide();
            editor.menuOpen = false;
        });
    }

    /**
     * checks if the menu needs to be opened or closed.
     */
    menuHandler(x, y) {
        //Open Menu
        if (this.menuOpen) {
            this.menu.hide();
            this.menuOpen = false;
        } else {
            this.menuX = x;
            this.menuY = y;

            this.menu.show([x + this.xOffset, y + this.yOffset]);
            this.menuOpen = true;
        }
    }

    /**
     * Prompts a input field for menu options that need text input
     * @param {String} message the message to display
     * @param {String} def default input
     * @param {Function} call the callback to call with the input
     */
    static textOption(message, def, call) {
        let input = prompt(message, def);

        if (input == null || input === "") {
            return false;
        } else {
            return call(input);
        }
    }

}

export default Editor;