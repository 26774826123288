import ArrayRepository from "./arrayRepository"

/**
 * Container for the Goal objects of the game
 */
class GoalRepository extends ArrayRepository {
    constructor() {
        super();
    }

    /**
     * Reset each goal in the repository
     */
    reset() {
        this.elements.forEach((goal) => {
            goal.reset();
        });
    }

    /**
     * Retrieve all shapes
     * @returns {Array} shapes of each element in the repository
     */
    getGoals() {
        return this.elements;
    }

    /**
     * Method to check in the field are hit
     * @returns {boolean} true if all goals are hit, else false
     */
    allGoalsHit() {
        if (this.elements.length > 0) {
            for (let i = 0; i < this.elements.length; i++) {
                let goal = this.elements[i];
                if ((!goal.isHit && !goal.notGoal) || (goal.isHit && goal.notGoal)) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }
}

export default GoalRepository;